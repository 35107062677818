








import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import { Id } from '../../user/domain/Types';
import CreativeForm from '../components/CreativeForm.vue';
import { CreativeService } from '../services/CreativeService';
import Page from '@/components/Page.vue';
import { CampaignService } from '../../campaign/services/CampaignService';
import { Creative } from '@/modules/creative/domain/Creative';
import { CreativeState } from '@/modules/creative/domain/CreativeState';

@Component({
    components: { Card, CreativeForm, Page },
})
export default class CreateCreative extends Vue {
    private isLoading: boolean = false;
    private campaignId: Id = '';
    private creative: Creative | Creative = {
        name: '',
        startDate: '',
        endDate: '',
        state: CreativeState.INACTIVE,
        categoryId: undefined,
        campaignId: undefined,
        media: '',
        type: '',
        duration: 15,
        zones: [],
        portalId: undefined,
        title: '',
        description: '',
        url: '',
    };

    private async mounted() {
        if (this.$route.params.campaignId) {
            this.campaignId = this.$route.params.campaignId;
        }
        await this.fetchCampaign();
    }

    private async fetchCampaign() {
        this.isLoading = true;
        try {
            const campaign = await CampaignService
                .getCampaignBy(this.campaignId, this.$store.getters['sessionStore/token']);
            this.creative.campaignId = campaign.id;
        } catch {
            this.$router.push('/campaign-listing');
        }
        this.isLoading = false;
    }

    private async onCreativeSave(creative: Creative) {
        this.isLoading = true;
        try {
            await CreativeService.save(
                creative,
                this.$store.getters['sessionStore/token'],
            );
            // @ts-ignore
            return this.$router.push(`/view-campaign/${this.campaignId}`);
        } catch {
            this.$notify.error({
                title: 'ERREUR',
                message:
                    'Erreur lors de la création de votre créatif. Veuillez vérifier vos champs et réessayer.',
            });
        }
        this.isLoading = false;
    }
}
